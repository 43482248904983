import React, { useEffect, useState } from "react";
import "../styles/LeftColumn.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTelegramPlane } from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faPhone,
  faCog,
  faPlus,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "./Modal";

interface LeftColumnProps {
  selectionState: string;
  setSelectionState: (val: string) => void;
}

const LeftColumn: React.FC<LeftColumnProps> = ({
  selectionState,
  setSelectionState,
}) => {
  const [isEquipmentModalOpen, setIsEquipmentModalOpen] = useState(false);
  const [isProfileSettingsModalOpen, setIsProfileSettingsModalOpen] =
    useState(false);
  const [showCopied, setShowCopied] = useState(false);

  const emailAddress = "servicedesk@figurait.ru";

  const toggleEquipmentModal = () => {
    setIsEquipmentModalOpen(!isEquipmentModalOpen);
  };

  const toggleProfileSettingsModal = () => {
    setIsProfileSettingsModalOpen(!isProfileSettingsModalOpen);
  };

  const copyToClipboard = async () => {
    try {
      // Пробуем использовать современный API
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(emailAddress);
        setShowCopied(true);
        setTimeout(() => setShowCopied(false), 2000);
      } else {
        // Fallback для случаев, когда API недоступен
        const textArea = document.createElement("textarea");
        textArea.value = emailAddress;
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
          document.execCommand('copy');
          textArea.remove();
          setShowCopied(true);
          setTimeout(() => setShowCopied(false), 2000);
        } catch (err) {
          console.error("Fallback: Oops, unable to copy", err);
          textArea.remove();
        }
      }
    } catch (err) {
      console.error("Failed to copy:", err);
    }
  };

  return (
    <div className="left-column">
      <div className="left-column-content">
        <h2>Меню</h2>
        <button
          className={`button ${selectionState === "devices" ? "btn-active" : ""}`}
          onClick={() => setSelectionState("devices")}
        >
          Оборудование
        </button>
        <button
          className={`button ${selectionState === "requests" ? "btn-active" : ""}`}
          onClick={() => setSelectionState("requests")}
        >
          Обращения
        </button>
      </div>
      <div className="contact-links">
        <h3>Контакты:</h3>
        <ul>
          <li>
            <a
              href="https://t.me/FiguraIT_bot"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faTelegramPlane} className="icon" /> Telegram
              Bot
            </a>
          </li>
          <li className="email-container">
            <a href={`mailto:${emailAddress}`}>
              <FontAwesomeIcon icon={faEnvelope} className="icon" /> E-mail
            </a>
            <button onClick={copyToClipboard} className="copy-button">
              <FontAwesomeIcon icon={faCopy} className="copy-icon" />
              {showCopied && <span className="copied-tooltip">Скопировано</span>}
            </button>
          </li>
          <li>
            <a href="tel:+74951288243">
              <FontAwesomeIcon icon={faPhone} className="icon" /> Call Center
            </a>
          </li>
        </ul>
      </div>
      <Modal
        show={isProfileSettingsModalOpen}
        onClose={toggleProfileSettingsModal}
      >
        <h2>Настройки профиля</h2>
        <form>
          <div className="form-group">
            <label htmlFor="username">Имя пользователя:</label>
            <input type="text" id="username" name="username" />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" />
          </div>
          <div className="form-group">
            <label htmlFor="password">Пароль:</label>
            <input type="password" id="password" name="password" />
          </div>
          <div className="form-group">
            <button type="submit" className="save-button">
              Сохранить
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default LeftColumn;